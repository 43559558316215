export default {
    computed: {
        logoDarkUrl() {
            return require(process.env.VUE_APP_LOGO_FULL_PATH);
        },
        logoLightUrl() {
            return require(process.env.VUE_APP_LOGO_PATH);
        },
        logoLightFullUrl() {
            return require(process.env.VUE_APP_LOGO_FULL_PATH);
        },
    },
};