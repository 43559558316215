<script>
import Layout from '../../layouts/auth';
import { authMethods } from '@/state/helpers';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { validationMixin } from '@/mixins/form/validation';
import brandMixin from "@/mixins/brand.mixin";

/**
 * Forgot Password component
 */
export default {
  mixins: [validationMixin, brandMixin],
  components: {
    Layout,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      email: '',
      resetErrorText: null,
      isSuccess: false,
    };
  },
  methods: {
    ...authMethods,
    tryToReset(values, actions) {
      this.loading = true;

      this.resetErrorText = null;
      this.isSuccess = false;

      this.sendResetEmail({
        email: this.email,
      })
        .then(() => {
          this.isSuccess = true;
        })
        .catch(({ response }) => {
          this.resetErrorText = this.handleResponseErrors(response, { values, actions });

          if (!this.resetErrorText) {
            this.resetErrorText = response?.data?.error?.message;
          }
        })
        .finally(() => (this.loading = false));
    },
  },
  metaInfo() {
    return {
      title: this.$t('Auth.Forgot.PageTitle'),
    };
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
          <div class="card overflow-hidden">
            <div class="bg-soft bg-primary">
              <div class="row">
                <div class="col-12">
                  <div class="ttext-dark p-4 text-center">
                    <h5>{{ $t('Auth.Forgot.ResetPassword') }}</h5>
                    <p>{{ $t('Auth.Forgot.ResetPasswordSubtitle') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div>
                <router-link to="/">
                  <div class="avatar-md profile-user-wid mb-4 mx-auto">
                    <span class="avatar-title rounded-circle bg-light">
                      <img :src="$getConfigValue('logoPath', 'VUE_APP_LOGO_URL')" alt height="42" />
                    </span>
                  </div>
                </router-link>
              </div>

              <b-alert :show="!isSuccess" class="mt-3" variant="primary">
                {{ $t('Auth.Forgot.EnterEmailAlert') }}
              </b-alert>

              <b-alert :show="isSuccess" class="mt-3" variant="success">
                {{ $t('Auth.Forgot.EmailBeenSent') }}
              </b-alert>

              <div class="p-2">
                <b-alert :show="!!resetErrorText" class="mb-4" variant="danger" dismissible>
                  {{ resetErrorText }}
                </b-alert>

                <Form @submit="tryToReset">
                  <div class="mb-3">
                    <Field name="email" rules="required|email" :label="$t('Form.Email')" v-slot="{ errors, field }">
                      <label for="email">{{ $t('Form.Email') }}</label>
                      <input
                        type="email"
                        v-model="email"
                        class="form-control"
                        id="email"
                        :placeholder="$t('Form.EmailPlaceholder')"
                        :class="{ 'is-invalid': errors.length }"
                        v-bind="field"
                      />

                      <ErrorMessage name="email" class="invalid-feedback" />
                    </Field>
                  </div>
                  <div class="mb-3 row mb-0">
                    <div class="col-12 text-end">
                      <button class="btn btn-dark w-md" type="submit">
                        {{ $t('Auth.Forgot.Reset') }}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->

          <div class="mt-5 text-center">
            <p>
              {{ $t('Auth.Forgot.RememberIt') }}
              <router-link to="/login" class="fw-medium text-primary">{{ $t('Auth.Forgot.SignIn') }}</router-link>
            </p>
            <p>© {{ new Date().getFullYear() }}</p>
          </div>
        </b-overlay>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
