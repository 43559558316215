<script>
import LanguageSelector from '@/components/LanguageSelector';

export default {
  components: {
    LanguageSelector,
  },
};
</script>

<template>
  <div>
    <div class="auth-layout__language-selector">
      <LanguageSelector />
    </div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.auth-layout {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__language-selector {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>

<style lang="scss">
.app-rtl {
  .auth-layout__language-selector {
    left: 20px;
    right: unset;
  }
}
</style>
