<template>
  <b-dropdown v-if="languages.length > 1" right menu-class="dropdown-menu-end" variant="black">
    <template v-slot:button-content>
      <img v-if="currentLocale.flag" class="header-lang-toggler" :src="currentLocale.flag" :alt="currentLocale.title" />
      <span v-else class="lang__tigle-flag"> {{ currentLocale.language }} </span>
    </template>

    <b-dropdown-item v-for="(locale, i) in languages" :key="`locale-${i}`" @click="changeLocale(locale.language)">
      <div class="d-flex align-items-center rtl-row-reverse">
        <img v-if="locale.flag" class="me-1" height="12" :src="locale.flag" :alt="locale.title" />
        <span v-else class="lang__tigle-flag lang__tigle-flag--small me-1">{{ locale.language }}</span>
        {{ locale.title }}
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {enabledLanguages} from "../i18n";

export default {
  name: 'LanguageSelector',
  props: {
    toggleClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      languages: enabledLanguages,
    };
  },
  computed: {
    currentLocale() {
      return this.languages.find((language) => this.$i18n.locale === language.language);
    },
  },
  methods: {
    changeLocale(locale) {
      localStorage.setItem('lang', locale);
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style lang="scss" scoped>
.lang {
  &__tigle-flag {
    border: 1px solid black;
    color: black;
    background: white;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 20px;
    width: 30px;
    text-transform: uppercase;
    font-weight: 600;
  }

  &__tigle-flag--small {
    height: 15px;
    width: 24px;
    font-size: 11px;
    font-weight: 500;
  }
}
::v-deep(.dropdown-toggle) {
  border: 0;
}
</style>
